//import logo from './logo.svg';
import {Container, Image, Jumbotron,Col, Row, Button} from 'react-bootstrap';
import './App.css';

function App() {
  return (
    <Container className="mt-4">
    <Jumbotron>
    <Row className="justify-content-md-center">
     <Col className="col-8">
    <Image src="LA_NOVA_LOGO.png" fluid/>
    </Col>
    </Row>
    <br/>
    <br/>
    <h1 style={{"text-align":"center"}}><span ><b>[Virtual] Holiday Party 2020</b></span></h1>
    <br />
    <div className="text-center">
    <Button variant="info btn-lg" href="https://forms.gle/tc3PHQZUAsnY4umM8" target="_blank" rel="noreferrer">RSVP HERE</Button>
    </div>
    <p>&nbsp;</p>
    <p><span ><strong>When: December 17th 2020, 4pm</strong></span></p>
    <p><span ><strong>Where: <a href="http://vr.lanovatile.com" target="_blank" rel="noreferrer">http://vr.lanovatile.com</a></strong></span></p>
    <p><span ><strong>What: </strong></span> We will be hosting a gathering in a virtual world called <a href="https://cryptovoxels.com" target="_blank" rel="noreferrer" >Cryptovoxels.</a></p>
    <p>Come and join us to check out the entries for our voxel ornament competition! Also make sure you sign the guest book when you arrive as we will be making a donation to <a href="https://www.searchhomeless.org/" target="_blank" rel="noreferrer">SEARCH Houston </a>on behalf of everyone that attends.</p>
    <br />
    <h4>Ornament Competition</h4>

    <p>Are you interested in creating an ornament to hang on our tree and compete for $1,000 in prizes?</p>
    <p>All you need to do is download <a href="blank_ornament.vox">this</a> template ornament and modify it with a software called <a href="https://ephtracy.github.io/" target="_blank" rel="noreferrer">MagicaVoxel</a>.</p>
    <p><b>Submissions are due by December 14th at midnight so we can hang them on our virtual tree!</b></p>
    <p>We will hang all of the submitted ornaments on our virtual tree and have a panel of judges come and select their favorite ones!</p>
    <p><i>Read below to learn how to do it and join us on December 10th at noon for a live Zoom tutorial on working inside of MagicaVoxel. <a href="https://www.google.com/url?q=https%3A%2F%2Fus02web.zoom.us%2Fj%2F88086018709%3Fpwd%3DdTQrYVh0aVlhaEpRR2FFdExKQ1d1Zz09&sa=D&ust=1606320284876000&usg=AOvVaw2wMh-Qa4L4Du3WHUPRf39-" rel="noreferrer" target="_blank">Click here </a> for a link to the meeting.</i></p>
    <p>&nbsp;</p>
    <Container>
    <Row className="justify-content-md-center">
    <Col className="col-6 text-align-center">

    <Image src="ornament.png" roundedCircle fluid/>
    </Col>
    </Row>
    </Container>
    <br />
    <br />
    <h4>Creating an Ornament</h4>
    <br />
    <p>You will use a free voxel editing software called MagicaVoxel to create your ornament. We have created a simple ornament template for you to start with or you are welcome to create your own ornament from scratch!</p>
    <div className="text-center">
    <Row className="align-items-center justify-content-md-center">
    <Col>
    <Image src="monu4.png" style={{"border-radius": "10px 10px 0 0"}} fluid/>
    <Button variant="info btn-lg btn-block" style={{"border-radius": "0px 0px 10px 10px"}} href="https://ephtracy.github.io/" target="_blank" rel="noreferrer">Get MagicaVoxel</Button>
    </Col>

    <Col>
    <Image src="blank.png" style={{"border-radius": "10px 10px 0 0"}} fluid/>
    <Button variant="info btn-lg btn-block" style={{"border-radius": "0px 0px 10px 10px"}} href="blank_ornament.vox">Download Template</Button>
    </Col>
    </Row>
    <br/>

    </div>
    <br/>
    <p><small><i>Note: Make sure and read the FAQ section under the download buttons in case you have trouble opening the software.</i></small></p>
    <p>If you are starting from scratch please set the dimensions of your model to 32 32 32 in the top right of the MagicaVoxel editor. You are welcome to utilize the entire box but leave a "top" like the above image for hanging purposes.</p>
    <p>MagicaVoxel takes a little getting used to. If you are starting with our template you will spend most of your time removing voxels from the model and painting the remaining voxels.</p>
    <p>We suggest you take a moment and watch the below YouTube video and/or read <a href="https://www.raywenderlich.com/375-magicavoxel-3d-art-tutorial" target="_blank" rel="noreferrer">this awesome tutorial</a> to help you better navigate the software.</p>
    <p>Also join us on December 10th at noon for a <a href="https://www.google.com/url?q=https%3A%2F%2Fus02web.zoom.us%2Fj%2F88086018709%3Fpwd%3DdTQrYVh0aVlhaEpRR2FFdExKQ1d1Zz09&sa=D&ust=1606320284876000&usg=AOvVaw2wMh-Qa4L4Du3WHUPRf39-" target="_blank" rel="noreferrer">live Zoom call </a>screen share on making an ornament!</p>
    <p><b>Remember to save the date!!!</b></p>
    <br />
    <h6><b>Rules</b></h6>
    <ul>
      <li>Voxel model file should be exactly 32x32x32 with the top of your ornament touching the top of the box that contains it. It's ok if the ornament is smaller than 32x32x32 just make sure it's pushed to the top.</li>
      <li>You can make anything you want as long as it fits inside of the box! A snowflake, cute animal, your company logo, doesn't matter! Just have fun with it. </li>
      <li>Limit of 2 ornaments submitted per person.</li>
      <li>The ornament will be rotating slowly when it's hung on the tree.</li>
      <li>Ornaments will be judged by a panel consisting of various other parcel owners and citizens of Cryptovoxels.</li>
    </ul>
    <br/>
    <br/>

    <h6>Beginner Video</h6>
    <div className="embed-responsive embed-responsive-16by9">
      <iframe title="MagicaVoxel Tutorial" width="560" height="315" src="https://www.youtube.com/embed/OQmR_dBLG3o" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <div className="text-center">
    La Nova Tile Importers
    <br />
    2325 Fannin St Houston, TX 77002
    <br />
    713-796-9050
    <br />
    <Button variant="link" href="https://www.lanovatile.com">www.lanovatile.com</Button>
    </div>
    </Jumbotron>
    </Container>
  );
}

export default App;
